.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 0px;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  vertical-align: middle;
}
.tg .tg-c3ow {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-7btt {
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-0pky {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-tlx9 {
  background-color: #fff;
  color: #333;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-baqh {
  text-align: center;
  vertical-align: middle;
}
.tg .tg-baqh2 {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}
.tg .tg-amwm {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
.tg .tg-zwfm {
  background-color: #d88d1e;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}
.tg .tg-u4ur {
  background-color: #d88d1e;
  text-align: left;
  vertical-align: top;
}
.tg .tg-cuakz {
  text-align: center;
  vertical-align: top;
  background-color: #317a75;
  color: #fff;
  font-weight: bold;
}

.gs {
  border-collapse: collapse;
  border-spacing: 0;
}
.gs td {
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.gs th {
  border-color: #e0e0e0;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  vertical-align: middle;
}
.gs .gs-baqh {
  text-align: center;
  vertical-align: top;
}
.gs .gs-amwm {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  background: #0e9c94;
}

.bg-hijau {
  background-color: #e1f0ff;
}

.bg-kuning {
  background-color: #fdf4de;
}

.bg-merah {
  background-color: #fbe2e5;
}

.bg-hijau-solid {
  background-color: #317a75;
  color: #fff;
  font-weight: 500;
}

.select-desa {
  margin-left: -17rem;
}

@media (max-width: 480px) {
  .select-desa {
    margin-left: -4rem;
  }
}

@media (max-width: 520px) {
  .bg-gray p {
    font-size: 1rem;
  }
  .bold-text {
    font-size: 0.75rem !important;
  }
  .card-mobile-margin {
    margin-bottom: 1rem;
  }
}

.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #ddd;
}
