@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

#content_jumbotron {
  background: #642ee2;
  border-radius: 30px 0px 0px 30px;
  width: 100%;
  /* height: 100vh; */
  /* padding: 0 0 0 28px; */
  background-image: url("../../assets/dots.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top right;
}

.jumbotron_ h4 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* color: #ffffff; */

  position: relative;
  bottom: 12px;
  margin-left: 26px;
}

.jumbotron_ h1 {
  font-family: "Inter", sans-serif;
  /* font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 66px;

  letter-spacing: 0.005em;
  text-transform: uppercase;

  color: #ffffff;
  margin-top: 1rem; */

  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  /* identical to box height, or 138% */

  /* Base/Light */

  /* color: #ffffff; */
}

.header_jumbotron > img,
.header_jumbotron > hr,
.header_jumbotron > h4 {
  display: inline-block;
}

.login-content {
  padding: 162px 122px 0 122px;
}

.hr_line {
  border-left: 4px solid #ffffff;
  height: 40px;
  border-top: none;
  border-radius: 2px;
  margin-left: 24px;
  opacity: 1;
}

.logo_ {
  width: 55px;
  position: relative;
  bottom: 28px;
}

.logo_dss {
  width: 110px;
  position: relative;
  bottom: 28px;
}

.bugisse_text {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px 12px 10px 12px;
  margin-top: 1rem;
  font-family: "Inter", sans-serif;
  font-style: oblique;
  font-size: 16px;
  text-align: center;
  position: relative;
  bottom: 10px;
  top: -20px;
}

.items-tes {
  background: red;
  width: 150px;
  height: 100px;
  width: 28rem;
}

#slide-login img {
  width: 500px;
}

.login-form {
  margin-top: 50px;
  /* padding: 28px; */
  width: 100%;
}

.title_logo {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  /* color: #327a6e; */
  margin-top: 28px;
}

.login-form h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  letter-spacing: 0.005em;

  color: #202020;
  margin-bottom: 32px;
}

.login-form label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.005em;

  color: #000000;
}

.login-form input {
  width: 100%;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.005em;

  color: #6d6d6d;
  padding: 16px;

  background: #ffffff;
  /* Color Foundation/Neutral/neutral-300 */

  border: 1px solid #e4e9f2;
  /* Inner/Shadow 1 */

  box-shadow: inset 1px 2px 2px rgba(21, 26, 48, 0.05);
  border-radius: 4px;
}

.btn-general,
.btn-general:hover {
  background: #452292;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.005em;

  color: #ffffff;
}

.icons-custom {
  color: #000000;
  position: relative;
  bottom: 28px;
  right: 10px;
  float: right;
}

/* .icon_content {
  position: absolute;
  left: 70%;
  cursor: pointer;
} */

/* #content_jumbotron {
  height: 100vh;
  padding-bottom: 32px;
  padding-top: 32px;
  background: #642ee2;
  border-radius: 16px;
  width: 100%;
  height: 100%;
} */

#crafted {
  position: absolute;
  bottom: 35px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;

  letter-spacing: 0.005em;

  color: #78777b;
}

#crafted span {
  color: #354c9f;
}

#crafted span a {
  color: #1d4ed8;
}
@media (max-width: 991.98px) {
  /* #slide-login img {
    width: 20px;
  } */
  #content_jumbotron {
    display: none;
  }
}

@media (max-width: 576px) {
  .login-form {
    padding: 0;
    /* width: 358px; */
  }
}

@media (min-width: 1367px) {
  .bugisse_text {
    margin-top: 2rem;
  }
}

@media (max-width: 1366px) {
  .icon_content {
    left: 74%;
  }

  #slide-login img {
    width: 415px;
    position: relative;
    bottom: 21px;
  }

  .login-content {
    padding: 50px 50px 50px 50px;
  }

  .logo_monev {
    width: 250px;
  }

  .title_logo {
    margin-top: 20px;
    /* color: #327a6e; */
    /* margin-left: 55px; */
  }

  .login-form {
    margin-top: 40px;
    padding: 0 0px 0px 46px;
    /* width: 358px; */
  }

  .jumbotron_ h4 {
    font-size: 14px;
    line-height: 21px;
    position: relative;
    bottom: 18px;
  }

  .jumbotron_ h1 {
    font-size: 18px;
  }
  /* #content_jumbotron {
    padding: 28px;
  } */

  .content_jumbotron_ {
    position: relative;
    bottom: 28px;
  }
}

/* @media (max-width: 1536px) {
  #slide-login img {
    width: 620px;
  }
} */

.pwd-container {
  position: relative;
  width: auto;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 42px;
}

@keyframes example {
  from {background-color: red;}
  to {background-color: yellow;}
}

.float {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 20px;
  right: 50px;
  color: #FFF;
  border: none;
  border-radius: 50px;
  text-align: center;
  font-size: 40px;
  /* box-shadow: 2px 2px 3px #999; */
  background-color: transparent;
  z-index: 100;
  /* animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: infinite; */
}
